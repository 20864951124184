import React, { useEffect, useState } from 'react';
import './Game.css';
import { BsThreeDots, BsX } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const App = () => {
  const { t, i18n } = useTranslation();
  const [players, setPlayers] = useState([]);
  const [startingChips, setStartingChips] = useState(0);
  const [chipCost, setChipCost] = useState(10);
  const [dealerId, setDealerId] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPlayer, setEditPlayer] = useState(null);
  const [round, setRound] = useState(0);
  const [roundHistory, setRoundHistory] = useState([]);
  const [showRoundHistory, setShowRoundHistory] = useState(false);
  const [roundHistoryOpen, setRoundHistoryOpen] = useState([]);
  const [showEndGamePopup, setShowEndGamePopup] = useState(false);
  const [endGameResults, setEndGameResults] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showDealerPopup, setShowDealerPopup] = useState(false); // commented
  
  useEffect(() => {
    if(firstLoad){
      getInitial()
    }
  });

  const getInitial = () => {
    setPlayers(getInitialPlayers())
    setStartingChips(getInitialStartingChips())
    setChipCost(getInitialChipCost())
    setRound(getInitialRound())
    setRoundHistory(getInitialRoundHistory())
    setFirstLoad(false)
  }
  const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  const getInitialPlayers = () => {
    const storedPlayers = isJson(Cookies.get('players'))?Cookies.get('players'):null;
    const checkStoredPlayers = (storedPlayers)?JSON.parse(storedPlayers):[]
    return (storedPlayers && checkStoredPlayers.length!==0)? JSON.parse(storedPlayers) : [
      { id: 1, name: 'Player 1', chips: 0, thisGame: 0 },
      { id: 2, name: 'Player 2', chips: 0, thisGame: 0 },
    ];
  };
  const getInitialStartingChips = () => {
    const storedStartingChips = Cookies.get('startingChips');
    return storedStartingChips ? JSON.parse(storedStartingChips) : 0;
  };
  const getInitialChipCost = () => {
    const storedChipCost = Cookies.get('chipCost');
    return storedChipCost ? JSON.parse(storedChipCost) : 5;
  };
  const getInitialRound = () => {
    const storedRound = Cookies.get('round');
    return storedRound ? JSON.parse(storedRound) : 0;
  };
  const getInitialRoundHistory = () => {
    const storedRoundHistory = Cookies.get('roundHistory');
    return storedRoundHistory ? JSON.parse(storedRoundHistory) : [];
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const handleRoundHistoryToggle = (index) => () => {
    const newRoundHistoryOpen = [...roundHistoryOpen];
    newRoundHistoryOpen[index] = !newRoundHistoryOpen[index];
    setRoundHistoryOpen(newRoundHistoryOpen);
  };
  const handleThisGameChange = (event,player_id) => {
    savePlayers(
      players.map((p) =>
      p.id === player_id ? { ...p, thisGame: parseInt(event.target.value) } : p,
      ),
    )
  };
  const addPlayer = () => {
    if (players.length < 12) {
      savePlayers([
        ...players,
        { id: players.length + 1, name: `Player ${players.length + 1}`, chips: startingChips, thisGame: 0, },
      ]);
    }
  };

  const restGame = ()=>{
    if (window.confirm(t('endGameConfirm'))) {
      Object.keys(Cookies.get()).forEach(function(cookieName) {
        var neededAttributes = {
          // Here you pass the same attributes that were used when the cookie was created
          // and are required when removing the cookie
        };
        Cookies.remove(cookieName, neededAttributes);
      });
      getInitial()
      setShowEndGamePopup(false)
    }
  }
  const savePlayers = (newPlayers)=>{
    setPlayers(newPlayers);
    Cookies.set('players', JSON.stringify(newPlayers));
  }
  const saveDealerId = (newDealerId)=>{
    setDealerId(newDealerId);
    Cookies.set('dealerId', JSON.stringify(newDealerId));
  }
  const saveRound = (newRound)=>{
    setRound(newRound);
    Cookies.set('round', JSON.stringify(newRound));
  }
  const saveRoundHistory = (newRoundHistory)=>{
    setRoundHistory(newRoundHistory);
    Cookies.set('roundHistory', JSON.stringify(newRoundHistory));
  }
  const saveStartingChips = (newStartingChips)=>{
    setStartingChips(newStartingChips);
    Cookies.set('startingChips', JSON.stringify(newStartingChips));
  }
  const saveChipCost = (newChipCost)=>{
    setChipCost(newChipCost);
    Cookies.set('chipCost', JSON.stringify(newChipCost));
  }
  
  const endRound = () => {
    const allPlayerThisGameZero = players.every((player) => player.thisGame === 0);
  
    if (allPlayerThisGameZero) {
      return;
    }
    const dealerTotal = players.reduce((total, p) => (p.id !== dealerId ? total - p.thisGame : total), 0);
    savePlayers(
      players.map((player) => {
        if (player.id === dealerId) {
          return { ...player, chips: player.chips + dealerTotal, thisGame: 0 };
        } else {
          return { ...player, chips: player.chips + player.thisGame, thisGame: 0 };
        }
      }),
    );
    saveRound(round + 1);
    
    const roundData = players.map((player) => ({
        id: player.id,
        thisGame: (player.id === dealerId)?dealerTotal:player.thisGame,
        isDealer: (player.id === dealerId),
    }));

    saveRoundHistory([...roundHistory, roundData]);
  };
  

  const endGame = () => {
    const results = players.map((player) => ({
      name: player.name,
      total: player.chips,
      cash: player.chips * chipCost,
    }));
  
    setEndGameResults(results);
    setShowEndGamePopup(true);
  };

  const saveEdit = () => {
    savePlayers(players.map((p) => (p.id === editPlayer.id ? editPlayer : p)));
    setShowEditPopup(false);
  };

  return (
    <div className="App">
      {/* Header */}
      <header>
        <button onClick={addPlayer}>{t('addPlayer')}</button>
        <div className="round" onClick={() =>{ 
            if(roundHistory.length > 0){
                setShowRoundHistory(true)
            }
        }}>{t('round')} {round} {t('roundTotal')} {' ('+roundHistory.length+')'}</div>
        <button className="menu-open" onClick={() => setMenuOpen(!menuOpen)}><BsThreeDots /></button>
      </header>
      <div className={`menu${menuOpen ? ' open' : ''}`}>
        <button className="menu-close" onClick={() => setMenuOpen(false)}><BsX /></button>
        <div className="menu-content">
            <label>
                {t('startChips')}
                <input
                type="number"
                value={startingChips}
                onChange={(e) => {
                  saveStartingChips(parseInt(e.target.value))
                }}
                />
            </label>
            <label>
                {t('chipsCost')}
                <input
                type="number"
                value={chipCost}
                onChange={(e) => saveChipCost(parseInt(e.target.value))}
                />
            </label>
            <div className="language-switcher">
              <button onClick={() => changeLanguage('en')}>{t('lanEn')}</button>
              <button onClick={() => changeLanguage('zh-Hant')}>{t('lanZh')}</button>
            </div>
            <button
                className="end-game"
                onClick={() => {
                    endGame();
                    setMenuOpen(false);
                }}
            >
                {t('endGame')}
            </button>
        </div>
      </div>


      {/* Content */}
      <main>
        <div className="player-header">
            <span>{t('playerName')}</span>
            <span>{t('playerTotal')}</span>
            <span>{t('playerGainLoss')}</span>
        </div>
        {players
          .sort((a, b) => (a.id === dealerId ? -1 : b.id === dealerId ? 1 : a.id - b.id))
          .map((player) => (
            <div
              key={player.id}
              className={`player ${dealerId === player.id ? 'dealer' : ''}`}
            >
              {dealerId === player.id && 
                <strong
                onClick={() => {
                    setEditPlayer({ ...player });
                    setShowEditPopup(true);
                }}
                >
                {player.name+((dealerId===player.id)?" ("+t('playerDealer')+")":"")}
                </strong>
              }

              {dealerId !== player.id && 
                <span
                onClick={() => {
                    setEditPlayer({ ...player });
                    setShowEditPopup(true);
                }}
                >
                {player.name}
                </span>
              }
              <span>{player.chips}</span>
              <div className="this-game">
                {dealerId !== player.id && (
                    <>
                    <button
                        onClick={() =>
                          savePlayers(
                            players.map((p) =>
                            p.id === player.id ? { ...p, thisGame: p.thisGame - 1 } : p,
                            ),
                        )
                        }
                    >
                        -
                    </button>
                    <input
                      type="number"
                      value={player.thisGame}
                      onChange={(e)=>handleThisGameChange(e,player.id)}
                      defaultValue={0}
                    />
                    <button
                        onClick={() =>
                          savePlayers(
                            players.map((p) =>
                            p.id === player.id ? { ...p, thisGame: p.thisGame + 1 } : p,
                            ),
                        )
                        }
                    >
                        +
                    </button>
                    </>
                )}
                {dealerId === player.id && (
                    <span>
                    {players.reduce((total, p) => (p.id !== dealerId ? total - p.thisGame : total), 0)}
                    </span>
                )}
                </div>
            </div>
          ))}
      </main>
      <div className="button-area">
        {/* <button onClick={() => setShowDealerPopup(true)}>Change Dealer</button> */}
        <button onClick={endRound}>{t('endRound')}</button>
      </div>
        {showEditPopup && (
        <div className="edit-popup">
            <button
            className="edit-popup-close"
            onClick={() => setShowEditPopup(false)}
            >
            <BsX />
            </button>
            <div className="edit-popup-content">
                <h3>{t('editPlayer')}</h3>
                <label>
                {t('editPlayerName')}
                <input
                type="text"
                value={editPlayer.name}
                onChange={(e) => setEditPlayer({ ...editPlayer, name: e.target.value })}
                />
                </label>
                <label>
                {t('editPlayerChips')}
                <input
                type="number"
                value={editPlayer.chips}
                onChange={(e) =>
                setEditPlayer({ ...editPlayer, chips: parseInt(e.target.value) })
                }
                />
                </label>
                <div className="edit-popup-content-button-area">
                  <button onClick={saveEdit}>{t('editPlayerSave')}</button>
                  <button
                      onClick={() => {
                          saveDealerId(editPlayer.id);
                          saveRound(0);
                          setShowEditPopup(false);
                      }}
                  >
                      {t('editPlayerChangeDealer')}
                  </button>
                </div>
            </div>
        </div>
        )}
        {/* {showDealerPopup && (
        <div className="dealer-popup">
            <h3>Select Dealer</h3>
            {players.filter((player) => player.id !== dealerId)
            .map((player) => (
            <div key={player.id} onClick={() => {
                saveDealerId(player.id);
                setShowDealerPopup(false); 
                saveRound(0);
            }}>
                {player.name}
            </div>
            ))}
            <button onClick={() => setShowDealerPopup(false)}>Cancel</button>
        </div>
        )} */}
        <div className={`round-history-popup${showRoundHistory ? ' open' : ''}`}>
        <button
          className="round-history-popup-close"
          onClick={() => setShowRoundHistory(false)}
        >
          <BsX />
        </button>
        {[...roundHistory].reverse().map((roundData, reversedIndex) => {
            const index = roundHistory.length - 1 - reversedIndex;
          return(
          <div key={index} 
          className={`round-history${reversedIndex === 0 ? " first" : ""}`}>
            <div className="round-history-header" onClick={handleRoundHistoryToggle(index)}>
            {t('round')} {index + 1}
            </div>
            <div className={`round-history-content${roundHistoryOpen[index] ? ' open' : ''}`}>
              {roundData.map((playerData) => {
                const player = players.find((p) => p.id === playerData.id);
                return (
                  <div key={playerData.id} className={`round-history-player${playerData.isDealer ? ' round-history-player-dealer' : ''}`}>
                    {player.name}: {playerData.thisGame}
                  </div>
                );
              })}
            </div>
          </div>
        )})}
        </div>
        {showEndGamePopup && (
            <div className="end-game-popup">
                <h2>{t('endGameResults')}</h2>
                <div className="end-game-popup-button-area">
                  <button onClick={()=> restGame()}>{t('endGameResultsRest')}</button>
                </div>
                {endGameResults.map((result, index) => (
                <div key={index} className="end-game-result">
                    <span>{result.name}</span>
                    <span>{t('endGameResultsTotal')} {result.total}</span>
                    <span>{t('endGameResultsCash')} {result.cash}</span>
                </div>
                ))}
                <button
                className="end-game-popup-close"
                onClick={() => setShowEndGamePopup(false)}
                >
                &times;
                </button>
            </div>
        )}
    </div>
  );
};
        
export default App;